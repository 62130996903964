<template>
  <div>
    <v-card class="mb-6 mt-3" elevation="0" outlined>
      <containerTitle title="Evaluation"> </containerTitle>
      <v-card-text>
        <lineFieldsArray
            :fieldAttrRow="{}"
            :fields="fields"
            :templateContent="result"
            :value="value.eval"
            @line-field-input="checkInput(...arguments)"
            :headerTitle="true"
            :addable="true"
            addButtTitle="Add Eval"
            :linePrefix="result.stepType + '_' + result.stepID + '_eval'"
        ></lineFieldsArray>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import lineButtons from "@/cfg/lineButtons.json";
import containerTitle from "@/commonComponents/containerTitle.vue";
import options from "@/cfg/options.json";

export default {
  components: {
    lineFieldsArray,
    containerTitle
  },
  data() {
    return { operatorList: [], rerunsObject: {} };
  },
  props: ["field", "fieldAttributes", "result", "value"],

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    fields() {
      let fields = {
        type: {
          type: "combobox",
          inputFieldFormatter: 'sipCallEvalCheck',
          fieldAttrInput: { class: "required" },
          name: "Type",
        },
        operator: {
          type: "select",
          associatedOption: options.pingEvalOperator,
          name: "Operator",
          fieldAttrInput: { class: "required" },
        },

        content: {
          type: "text",
          name: "Value",
          fieldAttrInput: { class: "required" },
        },
        else: {
          input: false,
          type: "string",
          string: "else",
          colAttrs: {
            style: "max-width: 50px;",
          },
        },

        result: {
          type: "select",
          associatedOption: options.ldapResult,
          name: "Result",
          fieldAttrInput: { class: "required" },
          default: "error",
        },
        action: {
          type: "select",
          associatedOption: options.evalAction,
          name: "Action",
          default: "stopTest",
          fieldAttrInput: { class: "required" },
        },
        info: {
          type: "text",
          name: "Info",
          fieldAttrInput: { class: "" ,maxlength:'100' },
        },
        remove: lineButtons.remove,
      };
      return fields;
    },

  },
  methods: {
    checkInput(value) {
      let localValue = { ...this.value };
      this.$set(localValue, "eval", value);
      this.$emit("input", localValue);
    },
  },
};
</script>